import { AppContext } from '@logistic/common';
import { Row } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import React, { useContext } from 'react';

const AppFooter = () => {
  const { config } = useContext(AppContext);
  return (
    <Footer className="app-footer">
      <div>
        <Row className="copyright" justify="space-between">
          {config.copyright}
        </Row>
      </div>
    </Footer>
  );
};

export default AppFooter;
