import { AppConfig } from '@logistic/common';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp(AppConfig.adminConfig);
firebase.analytics();

export const TaskState = firebase.storage.TaskState;
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
export type ConfirmationResult = firebase.auth.ConfirmationResult;
export const app = firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  return await auth.signInWithEmailAndPassword(email, password);
};

export const updatePassword = async (password: string, newPassword: string) => {
  const user = auth.currentUser;
  await auth.signInWithEmailAndPassword(user?.email ?? '', password);
  return await user?.updatePassword(newPassword);
};

export const forgotPassword = async (email: string) => {
  return await auth.sendPasswordResetEmail(email);
};
