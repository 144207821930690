import { AccountIcon, AppTheme, SearchBar, Utils } from '@logistic/common';
import { Col, Layout, Menu, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as AppLogo } from 'assets/img/logo.svg';
import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

const AppHeader = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const [form] = useForm();
  const history = useHistory();

  const search = (id: string) => {
    Utils.search(history, id, () => form.resetFields());
  };

  return (
    <Layout.Header className="app-header">
      <Row align={'middle'} justify={'space-between'}>
        <AppLogo />
        <Row align={'middle'}>
          <Menu mode="horizontal" selectedKeys={[`/${pathSnippets[0] ?? ''}`]}>
            <Menu.Item key="/order">
              <NavLink to="/order">{'貨單'}</NavLink>
            </Menu.Item>
            <Menu.Item key="/payment">
              <NavLink to="/payment">{'收款'}</NavLink>
            </Menu.Item>
            <Menu.Item key="/driver">
              <NavLink to="/driver">{'司機'}</NavLink>
            </Menu.Item>
            <Menu.Item key="/customer">
              <NavLink to="/customer">{'會員'}</NavLink>
            </Menu.Item>
            {Utils.isAdmin() && (
              <Menu.Item key="/config">
                <NavLink to="/config">{'設定'}</NavLink>
              </Menu.Item>
            )}
          </Menu>
          <Menu mode="horizontal" selectable={false}>
            {Utils.isLoggedIn() && (
              <Menu.Item>
                <NavLink to="/profile">
                  <Row align="middle">
                    <AccountIcon style={{ fontSize: 24, display: 'block' }} />
                    <Col
                      style={{
                        maxWidth: 64,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {localStorage.getItem('username')}
                    </Col>
                  </Row>
                </NavLink>
              </Menu.Item>
            )}
          </Menu>
          <SearchBar form={form} onFinish={search} style={{ marginLeft: AppTheme.insetMd, width: 230 }} placeholder="貨單搜尋" size="small" />
        </Row>
      </Row>
    </Layout.Header>
  );
};

export default AppHeader;
