import {
  AppConfig,
  AppConfigProvider,
  AppContext,
  AppTheme,
  Config,
  ForgetPasswordModal,
  LoadingIndicator,
  MaintenancePage,
  useConfig,
  usePricing,
  User,
  UserRole,
  UserService,
  useStore,
  Utils
} from '@logistic/common';
import { Layout, message, Space, Typography } from 'antd';
import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { analytics, auth, forgotPassword } from 'service/firebase';
import 'styles/admin.theme.less';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const ConfigPage = lazy(() => import('pages/Config/Config'));
const CustomerPage = lazy(() => import('pages/Customer'));
const CustomerDetailPage = lazy(() => import('pages/CustomerDetail'));
const DriverPage = lazy(() => import('pages/Driver'));
const DriverDetailPage = lazy(() => import('pages/DriverDetail'));
const InventoryDetailPage = lazy(() => import('pages/InventoryDetail'));
const LoginPage = lazy(() => import('pages/Login'));
const OrderPage = lazy(() => import('pages/Order'));
const OrderDetailPage = lazy(() => import('pages/OrderDetail'));
const PaymentPage = lazy(() => import('pages/Payment'));
const PaymentDetailPage = lazy(() => import('pages/PaymentDetail'));
const ProfilePage = lazy(() => import('pages/Profile'));

type AdminContextType = {
  user?: User;
  setUser: (user: User) => void;
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
};

export const AdminContext = React.createContext<AdminContextType>({
  setUser: () => console.log('setUser'),
  loggedIn: false,
  setLoggedIn: () => console.log('setLoggedIn'),
});

const App = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>();
  const [initializing, setInitializing] = useState(true);
  const [config, setConfig, configLoaded] = useConfig();
  const [pricing, setPricing, pricingLoaded] = usePricing();
  const [store, setStore, storeLoaded] = useStore();
  const [user, setUser] = useState<User>();
  const [loggedIn, setLoggedIn] = useState(false);
  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    window.onbeforeunload = () => window.scrollTo(0, 0);
    auth.onAuthStateChanged((user) => {
      if (user) {
        UserService.getUserProfile()
          .then((e) => {
            if (e.role == UserRole.ADMIN) {
              localStorage.setItem('uid', user.uid);
              localStorage.setItem('username', user.displayName ?? '用戶');
              localStorage.setItem('loggedIn', '1');
              localStorage.setItem('role', e?.admin?.role ?? '');
              analytics.setUserId(user.uid);
              setUser(e);
              setLoggedIn(true);
            } else {
              message.warn('用戶不存在!');
              clearUser();
            }
          })
          .catch(() => clearUser());
      } else {
        clearUser();
      }
    });
    message.config({ top: AppTheme.headerHeight + AppTheme.insetMd, maxCount: 1, prefixCls: `${AppConfig.antPrefixCls}-message` });
  }, []);

  useEffect(() => {
    if (configLoaded && pricingLoaded && (!Utils.isLoggedIn() || user)) {
      setInitializing(false);
      document.querySelector('.loader-container')?.remove();
    }
  }, [configLoaded, pricingLoaded, user]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
    openNotification();
  };

  const openNotification = () => {
    message.warn(
      <Space prefixCls={`${AppConfig.antPrefixCls}-space`} size={AppTheme.insetSm}>
        {'有新本版可供更新'}
        <Typography.Link onClick={reloadPage} underline>
          {'更新'}
        </Typography.Link>
        <Typography.Link onClick={() => message.destroy()} underline>
          {'略過'}
        </Typography.Link>
      </Space>,
      0
    );
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload(true);
  };

  const clearUser = () => {
    localStorage.removeItem('uid');
    localStorage.removeItem('username');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('role');
    setUser(undefined);
    setLoggedIn(false);
  };

  if (initializing) return null;
  if (config.maintenance == 'true') {
    return <MaintenancePage />;
  }

  return (
    <AppConfigProvider>
      <AppContext.Provider
        value={{
          config: config ?? ({ maintenance: 'true' } as Config),
          setConfig: setConfig,
          pricing: pricing ?? [],
          setPricing: setPricing,
          store: store,
          setStore: setStore,
          storeLoaded: storeLoaded,
          forgetPasswordModalVisible: forgetPasswordModalVisible,
          setForgetPasswordModalVisible: setForgetPasswordModalVisible,
        }}
      >
        <AdminContext.Provider
          value={{
            user: user,
            setUser: setUser,
            loggedIn: loggedIn,
            setLoggedIn: setLoggedIn,
          }}
        >
          <Layout>
            {Utils.isLoggedIn() && <AppHeader />}
            <div style={{ flex: 1, paddingTop: AppTheme.headerHeight }}>
              <Suspense fallback={<LoadingIndicator />}>
                <Switch>
                  {/* Pre auth */}
                  {!Utils.isLoggedIn() && <Route exact path="/login" component={LoginPage} />}
                  {!Utils.isLoggedIn() && <Redirect to="/login" />}
                  {/* Post auth */}
                  {Utils.isLoggedIn() && <Route exact path="/order" component={OrderPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/order/:id" component={OrderDetailPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/payment" component={PaymentPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/payment/:id" component={PaymentDetailPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/driver" component={DriverPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/driver/:id" component={DriverDetailPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/customer" component={CustomerPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/customer/:id" component={CustomerDetailPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/profile" component={ProfilePage} />}
                  {Utils.isLoggedIn() && <Route exact path="/inventory/:id" component={InventoryDetailPage} />}
                  {Utils.isLoggedIn() && Utils.isAdmin() && <Route exact path="/config" component={ConfigPage} />}
                  {Utils.isLoggedIn() && <Redirect to="/order" />}
                </Switch>
              </Suspense>
            </div>
            <AppFooter />
          </Layout>
          <ForgetPasswordModal forgotPassword={forgotPassword} />
        </AdminContext.Provider>
      </AppContext.Provider>
    </AppConfigProvider>
  );
};

export default App;
